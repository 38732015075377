export const DEPOSIT_CONFIG_EN = {
  title: 'Deposit Management',
  area: 'Area',
  distributor: 'Distributor',
  absolute: 'Absolute',
  percentage: 'Percentage',
  factorType: 'Factor Type',
  factorValue: 'Factor Value',
  depositType: 'Deposit Type',
  depositValue: 'Deposit Value',
}
