export const PAYMENT_REQUEST_ALL = 'all'
export const PAYMENT_REQUEST_CREATED = 'created'
export const PAYMENT_REQUEST_PAID = 'paid'
export const PAYMENT_REQUEST_CANCELLED = 'cancelled'
export const PAYMENT_REQUEST_VIGO = 'vigo'
export const PAYMENT_REQUEST_DISTRIBUTOR = 'distributor'
export const PAYMENT_REQUEST_SUPPLIER = 'supplier'
export const PAYMENT_REQUEST_DEBT = 'debt'
export const PAYMENT_REQUEST_REJECTED_INVOICE = 'invoice_rejected'

export const PAYMENT_REQUEST_AWAITING_APPROVE = 'awaiting_approve'

export const PAYMENT_FROM_GREATER_THAN_0 = 'greater_than_0'
export const PAYMENT_FROM_EQUAL_0 = 'equal_0'

export const PAYMENT_REQUEST_TOTAL_SELECT = {
  [PAYMENT_FROM_GREATER_THAN_0]: 'Greater than 0 ₫ ( > 0 ₫ ) ',
  [PAYMENT_FROM_EQUAL_0]: 'Equals 0 ₫  ( = 0 ₫ ) ',
}

export const ALLOW_REJECT_INVOICE = [
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_AWAITING_APPROVE,
]

export const ALLOW_RECORD_DEBT = [
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_AWAITING_APPROVE,
]

export const ALLOW_PAID = [
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_AWAITING_APPROVE,
  PAYMENT_REQUEST_DEBT,
]
export const ALLOW_CANCELLED = [
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_AWAITING_APPROVE,
  PAYMENT_REQUEST_REJECTED_INVOICE,
]

export const ENTITY_TYPE_DISTRIBUTOR_PO = 'distributor_purchase_order'
export const ENTITY_TYPE_VIGO_PO = 'vigo_purchase_order'
export const ENTITY_TYPE_PURCHASE_ORDER = 'purchase_order'
export const ENTITY_TYPE_INBOUND = 'inbound'
export const ENTITY_TYPE_CASH_RECONCILIATION = 'cash_reconciliation'

export const PAYMENT_REQUEST_STATUSES = [
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_DEBT,
  PAYMENT_REQUEST_PAID,
  PAYMENT_REQUEST_REJECTED_INVOICE,
  PAYMENT_REQUEST_AWAITING_APPROVE,
  PAYMENT_REQUEST_CANCELLED,
]

export const PAYMENT_REQUEST_TAB_MAPPING = {
  [PAYMENT_REQUEST_ALL]: 'ALL',
  [PAYMENT_REQUEST_CREATED]: 'CREATED',
  [PAYMENT_REQUEST_DEBT]: 'DEBT',
  [PAYMENT_REQUEST_REJECTED_INVOICE]: 'REJECTED INVOICE',
  [PAYMENT_REQUEST_AWAITING_APPROVE]: 'AWAITING APPROVE',
  [PAYMENT_REQUEST_PAID]: 'PAID',
  [PAYMENT_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PAYMENT_REQUEST_STATUS_SELECT = {
  [PAYMENT_REQUEST_CREATED]: 'CREATED',
  [PAYMENT_REQUEST_PAID]: 'PAID',
  [PAYMENT_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PAYMENT_REQUEST_STATUS_COLOR_MAPPING = {
  [PAYMENT_REQUEST_CREATED]: 'blue',
  [PAYMENT_REQUEST_PAID]: 'green',
  [PAYMENT_REQUEST_CANCELLED]: 'default',
  [PAYMENT_REQUEST_AWAITING_APPROVE]: 'orange',
  [PAYMENT_REQUEST_REJECTED_INVOICE]: 'red',
  [PAYMENT_REQUEST_DEBT]: 'purple',
}

export const PAYMENT_REQUEST_FROM_SELECT = {
  [PAYMENT_REQUEST_VIGO]: 'Vigo',
  [PAYMENT_REQUEST_DISTRIBUTOR]: 'Distributor',
}

export const PAYMENT_REQUEST_TO_SELECT = {
  [PAYMENT_REQUEST_VIGO]: 'Vigo',
  [PAYMENT_REQUEST_SUPPLIER]: 'Supplier',
}
