export const DEPOSIT_CONFIG_VI = {
  title: 'Quản lý ký quỹ',
  area: 'Khu vực',
  distributor: 'Nhà phân phối',
  absolute: 'Tuyệt đối',
  percentage: 'Phần trăm',
  factorType: 'Loại tiêu chí',
  factorValue: 'Giá trị tiêu chí',
  depositType: 'Loại ký quỹ',
  depositValue: 'Giá trị ký quỹ',
}
