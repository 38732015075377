import { PURCHASE_ORDER_LIST } from 'common/config/routes'
import { PurchaseOrder } from 'interfaces/purchaseOrder'

import {
  SUPPLIER_TYPE_MASTER_DISTRIBUTOR_HUB,
  SUPPLIER_TYPE_NORMAL,
} from './supplier'

export const PURCHASE_ORDER_TYPE_NORMAL = 'normal'
export const PURCHASE_ORDER_TYPE_RETAIL = 'retail'
export const PURCHASE_ORDER_TYPE_RETAIL_DPO = 'retail_dpo'

export const PURCHASE_ORDER_SUPPLIER_HUB = 'supplier_hub'
export const PURCHASE_ORDER_DISTRIBUTOR_HUB = 'distributor_hub'
export const TASK_CODE_PURCHASE_ORDER_MASSIVE =
  'purchase-order-massive-create-po-upload'
export const ENTITY_TYPE_PURCHASE_ORDER = 'purchase_order'
export const PURCHASE_ORDER_STATUS_ALL = 'all'

export const PAYMENT_METHOD_COD = 'cod'
export const PAYMENT_METHOD_PREPAYMENT = 'prepayment'

export const PAYMENT_STATUS_UNPAID = 'unpaid'
export const PAYMENT_STATUS_PAID = 'paid'

export const PAYMENT_METHOD_MAPPING = {
  [PAYMENT_METHOD_COD]: 'COD',
  [PAYMENT_METHOD_PREPAYMENT]: 'Prepayment',
}
export const PAYMENT_STATUS_MAPPING = {
  [PAYMENT_STATUS_UNPAID]: 'Unpaid',
  [PAYMENT_STATUS_PAID]: 'Paid',
}

export const PURCHASE_ORDER_HREF = {
  [PURCHASE_ORDER_TYPE_NORMAL]: `${PURCHASE_ORDER_LIST}/`,
  [PURCHASE_ORDER_TYPE_RETAIL]: `${PURCHASE_ORDER_LIST}/retail-po/`,
  [PURCHASE_ORDER_TYPE_RETAIL_DPO]: `${PURCHASE_ORDER_LIST}/d2d/`,
}

export const BODY_VALIDATION_CM3 = [
  'Điều chỉnh trợ giá NPP',
  'Điều chỉnh giá nhập thực tế',
  'Loại bỏ SKU có CM3 EST < ',
]

export const PURCHASE_ORDER_TYPE = {
  [PURCHASE_ORDER_TYPE_NORMAL]: 'Normal PO',
  [PURCHASE_ORDER_TYPE_RETAIL]: 'Retail PO',
  [PURCHASE_ORDER_TYPE_RETAIL_DPO]: 'D2D',
}
export const PURCHASE_ORDER_TYPE_SELECT = [
  {
    label: 'Normal PO',
    value: PURCHASE_ORDER_TYPE_NORMAL,
  },
  {
    label: 'Retail PO',
    value: PURCHASE_ORDER_TYPE_RETAIL,
  },
]
export const OPTION_PURCHASE_ORDER_TYPE = [
  {
    label: 'Normal PO',
    value: SUPPLIER_TYPE_NORMAL,
  },
  {
    label: 'Retail PO',
    value: SUPPLIER_TYPE_MASTER_DISTRIBUTOR_HUB,
  },
]

export const PURCHASE_ORDER_HUB_TAB = [
  PURCHASE_ORDER_SUPPLIER_HUB,
  PURCHASE_ORDER_DISTRIBUTOR_HUB,
]

export const PURCHASE_ORDER_STATUS = {
  CREATED: 'created',
  SUPPLIER_APPROVED: 'supplier_approved',
  APPROVED: 'approved',
  INBOUND_CREATED: 'inbound_created',
  INBOUNDED: 'inbounded',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  PENDING: 'pending',
  NEED_APPROVE_LEVEL_2: 'request_high_level_approved',
}
export const PURCHASE_ORDER_STATUS_MAPPING = {
  [PURCHASE_ORDER_STATUS_ALL]: 'ALL',
  [PURCHASE_ORDER_STATUS.NEED_APPROVE_LEVEL_2]: 'NEED APPROVE LEVEL 2',
  [PURCHASE_ORDER_STATUS.CREATED]: 'CREATED',
  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: 'SUPPLIER APPROVED',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'APPROVED',
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: 'INBOUND CREATED',
  [PURCHASE_ORDER_STATUS.INBOUNDED]: 'INBOUNDED',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'REJECTED',
  [PURCHASE_ORDER_STATUS.CANCELED]: 'CANCELED',
  [PURCHASE_ORDER_STATUS.PENDING]: 'PENDING',
}

export const PURCHASE_ORDER_STATUS_CREATE_DELIVERY = [
  PURCHASE_ORDER_STATUS.CREATED,
  PURCHASE_ORDER_STATUS.APPROVED,
  PURCHASE_ORDER_STATUS.INBOUNDED,
  PURCHASE_ORDER_STATUS.INBOUND_CREATED,
  PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED,
]

export const PURCHASE_ORDER_STATUS_ALLOW_EDIT = [
  PURCHASE_ORDER_STATUS.CREATED,
  PURCHASE_ORDER_STATUS.PENDING,
]

export const PURCHASE_ORDER_STATUS_COLOR_MAPPING = {
  [PURCHASE_ORDER_STATUS.CREATED]: 'blue',
  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: 'orange',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'green',
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: 'default',
  [PURCHASE_ORDER_STATUS.INBOUNDED]: 'default',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'red',
  [PURCHASE_ORDER_STATUS.CANCELED]: 'red',
  [PURCHASE_ORDER_STATUS.PENDING]: 'orange',
  [PURCHASE_ORDER_STATUS.NEED_APPROVE_LEVEL_2]: 'red',
}

export const TITLE_EXCEL_IMPORT_MASS_UPDATE_PO = [
  'PO Number',
  'Product Name',
  'SKU',
  'VAT Price',
  'Total Price',
  'Quantity',
  'Actual Amount',
  'VAT Amount',
  'Note',
]

export const ALLOW_CANCEL_PO = [
  PURCHASE_ORDER_STATUS.CREATED,
  PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED,
]

export const PURCHASE_ORDER_STATUS_TEXT = {
  [PURCHASE_ORDER_STATUS.CREATED]: 'CREATED',
  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: 'SUPPLIER APPROVED',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'APPROVED',
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: 'INBOUND CREATED',
  [PURCHASE_ORDER_STATUS.INBOUNDED]: 'INBOUNDED',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'REJECTED',
  [PURCHASE_ORDER_STATUS.CANCELED]: 'CANCELED',
  [PURCHASE_ORDER_STATUS.PENDING]: 'PENDING',
}
export const PURCHASE_ORDER_STATUS_TEXT_INDIA = {
  [PURCHASE_ORDER_STATUS.CREATED]: 'CREATED',
  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: 'SUPPLIER APPROVED',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'APPROVED',
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: 'INBOUND CREATED',
  [PURCHASE_ORDER_STATUS.INBOUNDED]: 'INBOUNDED',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'REJECTED',
  [PURCHASE_ORDER_STATUS.CANCELED]: 'CANCELED',
}

export type exportPOType = 'csv' | 'xlsx'
export const EXPORT_PURCHASE_ORDER_CSV = 'csv'
export const EXPORT_PURCHASE_ORDER_XLSX = 'xlsx'
export const EXPORT_PURCHASE_ORDER_TYPES = {
  [EXPORT_PURCHASE_ORDER_CSV]: 'Export to CSV',
  [EXPORT_PURCHASE_ORDER_XLSX]: 'Export to MS Excel',
}

export const PURCHASE_ORDER_HIDING_STATUS = [
  // PURCHASE_ORDER_STATUS_ENUM.INBOUNDED,
  // PURCHASE_ORDER_STATUS_ENUM.INBOUND_CREATED,
]

export const PURCHASE_ORDER_STATUS_TRANSITION = {
  [PURCHASE_ORDER_STATUS.CREATED]: [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED],

  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: [
    PURCHASE_ORDER_STATUS.APPROVED,
    PURCHASE_ORDER_STATUS.REJECTED,
    // PURCHASE_ORDER_STATUS.CANCELED,
  ],
  [PURCHASE_ORDER_STATUS.NEED_APPROVE_LEVEL_2]: [
    PURCHASE_ORDER_STATUS.APPROVED,
    PURCHASE_ORDER_STATUS.REJECTED,
    // PURCHASE_ORDER_STATUS.CANCELED,
  ],
  [PURCHASE_ORDER_STATUS.APPROVED]: [
    PURCHASE_ORDER_STATUS.INBOUND_CREATED,
    // PURCHASE_ORDER_STATUS.CANCELED,
  ],
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: [PURCHASE_ORDER_STATUS.INBOUNDED],
  [PURCHASE_ORDER_STATUS.PENDING]: [PURCHASE_ORDER_STATUS.CREATED],
}

export const RETAIL_PURCHASE_ORDER_STATUS_TRANSITION = {
  [PURCHASE_ORDER_STATUS.CREATED]: [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED],

  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: [
    PURCHASE_ORDER_STATUS.APPROVED,
    // PURCHASE_ORDER_STATUS.CANCELED,
  ],
  [PURCHASE_ORDER_STATUS.APPROVED]: [
    PURCHASE_ORDER_STATUS.INBOUND_CREATED,
    // PURCHASE_ORDER_STATUS.CANCELED,
  ],
  [PURCHASE_ORDER_STATUS.INBOUND_CREATED]: [PURCHASE_ORDER_STATUS.INBOUNDED],
}

export const PURCHASE_ORDER_STATUS_TRANSITION_ACTION = {
  [PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED]: 'Supplier Confirm',
  [PURCHASE_ORDER_STATUS.APPROVED]: 'PO Manager Approve',
  // [PURCHASE_ORDER_STATUS.APPROVED]: 'PO Manager Approval',
  [PURCHASE_ORDER_STATUS.REJECTED]: 'Reject',
  [PURCHASE_ORDER_STATUS.CANCELED]: 'Cancel',
  [PURCHASE_ORDER_STATUS.CREATED]: 'Confirm',
}

export const STATUS_ALLOW_EDIT_PRICE = [
  PURCHASE_ORDER_STATUS.SUPPLIER_APPROVED,
  PURCHASE_ORDER_STATUS.NEED_APPROVE_LEVEL_2,
]

export const buildPOExportData = (purchaseOrders: PurchaseOrder[]) => {
  const data = purchaseOrders.reduce((prev, purchaseOrder) => {
    const { items, number, status } = purchaseOrder
    const nextItems = items?.map((item) => {
      const { acceptedQuantity, price, quantity, rejectedQuantity, product } =
        item
      const totalAmount = acceptedQuantity * price
      return {
        'Mã PO': number,
        'Trạng thái': status,
        'Mã Hàng': product?.sku,
        'Mặt hàng': product?.name,
        'Tổng số lượng': quantity,
        'Đơn vị tính': product?.packageType,
        Giá: price,
        VAT: product?.vat,
        'Số lượng từ chối': rejectedQuantity,
        'Số lượng chấp nhận': acceptedQuantity,
        'Tổng tiền': totalAmount,
      }
    })

    return [...prev, ...nextItems]
  }, [])

  return data
}

export const buildPOExportDataDetail = (purchaseOrders: PurchaseOrder[]) => {
  const data = purchaseOrders.reduce((prev, purchaseOrder) => {
    const { items, number } = purchaseOrder
    const nextItems = items?.map((item) => {
      const { paymentPrice, price, quantity, note, product } = item
      return {
        'Mã Đơn Đặt Hàng': number,
        'Tên sản phẩm': product?.name,
        SKU: product?.sku,
        'Giá sau thuế': paymentPrice,
        'Tổng giá trị': price,
        'Số lượng': quantity,
        'Tổng giá thực tế': price * quantity,
        'Tổng giá sau thuế': paymentPrice * quantity,
        'Ghi chú': note,
      }
    })

    return [...prev, ...nextItems]
  }, [])

  return data
}
